<script setup lang="ts">
import type {
  FieldInterface,
  SliceInterface,
} from '@voix/types'
import type { PropType } from 'vue'
import type { VoixEventsFieldConfigurationInterface } from './types'

import { useField } from '@voix/composables/useField'
import FieldDescription from '@voix/voix-layer/components/FieldDescription.vue'
import VoixFieldHeader from '@voix/voix-layer/components/VoixFieldHeader.vue'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'
import { computed, defineModel } from 'vue'
import Multiselect from 'vue-multiselect'

const props = defineProps({
  element: {
    type: Object as PropType<SliceInterface>,
    required: false,
  },

  isPageField: {
    type: Boolean,
    required: false,
    default: false,
  },

  fieldData: {
    type: Object as PropType<FieldInterface>,
    default: null,
  },

  fieldConfiguration: {
    type: Object as PropType<VoixEventsFieldConfigurationInterface>,
    required: true,
  },
})
const { localValue, enabled } = useField(props)

const events = ref([])
const options = computed(() => {
  const list: Array<{ id: string, label: string, data: any }> = []

  events.value.forEach((event: any) => {
    list.push({
      id: event.id,
      label: `${event.title} | ${event.date_range.replace(/&nbsp;/g, ' ')}`,
      data: event,
    })
  })

  return list
})

$voixNuxtApi('/api/admin/atlantis/events', {
  params: {
    filter: JSON.stringify({
      deleted_at: {
        field: 'deleted_at',
        clause: 'whereNull',
      },
    }),
    first: 1000,
    page: 1,
  },
}).then((response: any) => {
  events.value = response.data.value.data

  if (localValue.value === null) {
    localValue.value = {
      id: -1,
      label: 'Select Event',
      value: '',
    }
  }
})
</script>

<template>
  <div style="height: 23rem;">
    <VoixFieldHeader v-model="enabled" :field-configuration="props.fieldConfiguration" />

    <Multiselect
      v-model="localValue"
      placeholder="Select Event"
      :options="options"
      track-by="label"
      label="label"
      class="mt-2"
    />

    <div class="w-full h-40 mt-4 rounded-t bg-gray-700">
      <img
        v-if="localValue?.data?.image"
        :src="localValue?.data?.image"
        class="h-full w-full object-cover"
      >
    </div>
    <div class="relative -top-4 z-10 p-2 pt-2.5 px-4 text-xs font-medium voix-admin-text voix-admin-bg-light rounded-b">
      Event Preview
    </div>

    <FieldDescription :field-configuration="props.fieldConfiguration" />
  </div>
</template>

<style scoped>
:deep(.multiselect__tags),
:deep(.multiselect__input),
:deep(.multiselect__single),
:deep(.multiselect__option) {
    font-size: 0.8rem;
    font-family: inherit;
    color: #6b7280;
}
:deep(.multiselect__option--highlight),
:deep(.multiselect__option--highlight::after) {
    background: #e5e7eb;
    outline: none;
    color: #6b7280;
}
:deep(.multiselect__content-wrapper) {
  overflow-x: hidden;
}
</style>
